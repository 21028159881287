import { useEffect, useState } from "react"

export const useActive = () => {
    const events = [
        'keypress', 'mousemove', 'touchstart', 'touchmove', 'click', 'scroll'
    ]
    const [active, setActive] = useState(false)
    const handleEvent = () => {
        setActive(true)
        events.forEach((event) => window.removeEventListener(event, handleEvent))
    }

    useEffect(() => {
        events.forEach((event) => window.addEventListener(event, handleEvent))
    }, [])

    return active
}