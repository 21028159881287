import { useStaticQuery, graphql } from "gatsby";
import { useLocale } from "../../hooks";
export const useSiteQuery = () => {
    const locale = useLocale()
    const {
        allContentfulGlobalSite: { edges }
    } = useStaticQuery(
        graphql`
            {
                allContentfulGlobalSite {
                    edges {
                        node {
                            node_locale
                            name
                            cookieDetails
                            headerMenuItems {
                                title
                                slug
                                menuItems {
                                    title
                                    slug
                                }
                            }
                            logo {
                                title
                                gatsbyImageData(placeholder: BLURRED)
                            }
                            schemaLogo: logo {
                                file {
                                    url
                                }
                            }
                            seoLogo: logo {
                                file {
                                    url
                                }
                                width
                                height
                                mimeType
                            }
                            unileverCopyright
                            footerText {
                                raw
                            }
                            footerItems {
                                title
                                externalLink
                                slug
                            }
                            footerUnileverLogo {
                                title
                                gatsbyImageData(quality: 100)
                            }
                            footerLogo {
                                title
                                gatsbyImageData
                            }
                            productCertifiedLogo{
                                title
                                gatsbyImageData
                            }
                            buyNowButton
                            relatedProducts
                            nutritionTitle
                            fatTitle
                            carboHydateTitle
                            proteinTitle
                            contactUsTitle
                            contactUsContent{
                                raw
                            }
                            carelinesScript
                            contactFormTitle
                            learnMoreTranslation
                        }
                    }
                }
            }
        `
    )

    const localData = locale
        ? edges.filter(({ node }) => node.node_locale === locale)
        : edges.filter(({ node }) => node.node_locale === 'en-CA')

    const site = localData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
    return site
}