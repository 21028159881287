import React from 'react'
import loadable from '@loadable/component'
import "../../styles/main.scss"
// const Header = loadable(() => import('./Header/Header'))
const Footer = loadable(() => import('./Footer/Footer'))
import Header from './Header/Header';

const Layout = ({ children }) => {
    return (
        <div className='page'>
            <Header />
            <main className="main">
                <div>{children}</div>
            </main>
            <Footer />
        </div>
    )
}

export default Layout