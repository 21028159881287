import React from 'react'
import * as styles from './header.module.scss'

import MenuItems from '../MenuItems/MenuItems'
import ChangeLanguage from '../ChangeLanguage/ChangeLanguage'
import MobileHeader from '../MobileHeader/MobileHeader'

import { useSiteQuery, useLocalePath } from '../../../hooks'

import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const Header = () => {
    const { headerMenuItems, logo } = useSiteQuery()
    return (
        <header>
            <MobileHeader items={headerMenuItems} logo={logo} />
            <div className={styles.header}>
                <section className={styles.container}>
                    <nav className={styles.menuItems}>
                        <div className={styles.logoImageContainer}>
                            <Link to={useLocalePath('')}>
                                <GatsbyImage image={logo.gatsbyImageData} class={styles.img} alt="Red Rose Header Logo" />
                            </Link>
                        </div>
                        <ul className={styles.menuLinks}>
                            {
                                headerMenuItems.map((item, index) => {
                                    return item && (
                                        item.menuItems
                                            ? <li className={`${styles.menuLink} ${styles.linking} ${item.menuItems && styles.menuHover}`} key={index}>
                                                <MenuItems items={item} />
                                            </li>
                                            : <li key={index} className={`${styles.menuLink} ${styles.linking} ${item.menuItems && styles.menuHover}`}>
                                                <Link className={styles.linkTitle} to={useLocalePath(item.slug)}>{item.title}</Link>
                                            </li>

                                    )
                                })

                            }
                            <li className={styles.translate}>
                                <ChangeLanguage />
                            </li>
                        </ul>
                    </nav>
                </section>
            </div>
        </header>
    )
}

export default Header