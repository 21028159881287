import React from "react";
import { mapSiteName } from "../../utils/mapping";
import { useLocale, useSiteQuery } from "../../hooks";
export const useSchema = (
    mode,
    data
) => {
    const locale = useLocale()
    const { schemaLogo } = useSiteQuery()
    const breadCrumbs = (array, current?) => {
        let breadCrumbs = []
        array.forEach((element, index) => {
            breadCrumbs.push({
                "@type": "ListItem",
                "position": index + 1,
                "item": {
                    "@id": element.slug,
                    "name": element.title,
                    "item": element.slug
                }
            })
        });

        current && breadCrumbs.push({
            "@type": "ListItem",
            "position": array.length + 1,
            "item": {
                "@id": `${mapSiteName[locale]}${current.slug}/`,
                "name": current.title,
                "item": `${mapSiteName[locale]}${current.slug}/`
            }
        })

        return breadCrumbs
    }
    switch (mode) {
        case "website":
            return {
                "@context": "https://schema.org/",
                "@type": "Website",
                "name": data.siteName ? data.siteName : '',
                "url": mapSiteName[locale]
            }
        case "home":
            return {
                "@context": "http://schema.org/",
                "@type": "Website",
                "url": mapSiteName[locale],
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": `${mapSiteName[locale]}search?s={search_term_string}`,
                    "query-input": "required name=search_term_string"
                }
            }
        case "breadcrumbs":
            return {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                // "itemListElement": data.breadCrumbs.map((breadcrumbs, index) => ({
                //     "@id": breadcrumbs.slug,
                //     "@type": "ListItem",
                //     "position": index + 1,
                //     "name": breadcrumbs.title,
                //     "item": breadcrumbs.slug
                // }))
                "itemListElement": breadCrumbs(data.breadCrumbs, data.current && data.current)
            }
        case "article":
            return {
                "@context": "https://schema.org",
                "@type": "Article",
                "name": data.title,
                "publisher": {
                    "@type": "Organization",
                    "name": "redroseteaca",
                    "logo": {
                        "@type": "ImageObject",
                        "url": schemaLogo?.file?.url
                    }
                },
                "url": `${mapSiteName[locale]}${data.slug}/`,
                "description": data.seoDescription,
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": `${mapSiteName[locale]}${data.slug}/`,
                },
                "image": {
                    "@type": "ImageObject",
                    "url": data.image?.file?.url,
                    "width": data.image?.width,
                    "height": data.image?.height
                },
                "datePublished": data.createdAt,
                "headline": data.title,
                "author": "redroseteaca",
                "dateModified": data.updatedAt
            }
        case "product":
            return {
                "@context": "http://schema.org/",
                "@type": "Product",
                "url": `${mapSiteName[locale]}${data.slug}/`,
                "name": data.seoTitle,
                "description": data.desc ? data.desc : '',
                "gtin": data.sku,
                "sku": data.sku,
                "releaseDate": data.createdAt,
                "image": data.image
            }
        case "itemList":
            return {
                "@context": "http://schema.org/",
                "@type": "itemList",
                "url": `${mapSiteName[locale]}${data.slug}/`,
                "name": data.seoTitle,
                "description": "",
                "numberOfItems": data.items.length,
                "itemListElement": data.items.map((item, index) => {
                    return {
                        "@type": "ListItem",
                        "position": index + 1,
                        "url": `${mapSiteName[locale]}${item.slug}/`,
                    }
                })
            }
    }
}