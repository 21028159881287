import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { useLocale } from '../../hooks'
import { cookieLang } from '../../utils/mapping'

const Scripts = () => {
    const location = useLocation()
    const events = [
        'keypress', 'mousemove', 'touchstart', 'touchmove', 'click', 'scroll'
    ]
    const locale = useLocale()
    const language = cookieLang[locale]
    const cookieScriptInit = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "usercentrics-cmp")
        script.setAttribute('src', "https://app.usercentrics.eu/browser-ui/latest/loader.js")
        script.setAttribute('data-settings-id', "4Xn5CKjy6")
        script.setAttribute('data-language', language)
        script.setAttribute('async', 'true')
        document.head.appendChild(script)
    }

    const gtag = () => {
        const script = document.createElement("script")
        script.setAttribute("id", "script-gtag")
        script.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag("consent", "default", {
            ad_user_data: "denied",
            ad_personalization: "denied",
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 1000
        });`
        script.setAttribute("async", "true")
        document.head.appendChild(script)
    }

    const gtm = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "script-gtm")
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-KH436W3');`
        document.head.appendChild(script)

        const noScript = document.createElement('noscript')
        noScript.setAttribute('id', "noscript-gtm")
        noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KH436W3" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        document.body.appendChild(noScript)
    }

    // const zendesk = () => {
    //     const script = document.createElement('script')
    //     script.setAttribute('id', "ze-snippet")
    //     script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=6ca65b6d-456a-4555-9ac6-582ffb96a3a0`)
    //     script.setAttribute('async', 'true')
    //     document.head.appendChild(script)
    // }

    // const zendeskFormHide = () => {
    //     const script = document.createElement('script')
    //     script.setAttribute('id', "zendesk-script")
    //     script.setAttribute('type', "text/javascript")
    //     let innerHTML = ''
    //     script.innerHTML = `
    //         window.zESettings = {
    //             webWidget: {
    //                 chat: { suppress: true },
    //                 helpCenter: { suppress: true },
    //                 talk: { suppress: true },
    //                 answerBot: { suppress: true },
    //                 contactForm: {
    //                     ticketForms: [{ id: 6292002231837, title: false }]
    //                 }
    //             }
    //         };
    //             zE('webWidget', 'setLocale', '${language}');
    //         `
    //     document.head.appendChild(script)
    // }

    const handleEvent = () => {
        cookieScriptInit()
        if(!document.getElementById("script-gtag")) gtag()
        if(!document.getElementById("script-gtm")) gtm()
        events.forEach((event) => window.removeEventListener(event, handleEvent))
    }

    useEffect(() => {
        events.forEach((event) => window.addEventListener(event, handleEvent))
    }, [location])

    setTimeout(() => {
        handleEvent()
    }, 6000)
    return (
        <></>
    )
}

export default Scripts