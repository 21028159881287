import React, { useState } from 'react'
import * as styles from './menu-items.module.scss';
import { Link } from 'gatsby';
import { useLocalePath } from '../../../hooks';
const MenuItems = ({ items }) => {
    const { title, slug, menuItems } = items

    const [showMenu, setShowMenu] = useState(false);
    return (
        <div
            onMouseOver={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
            onFocus={() => setShowMenu(true)}
            className={`heading heading-4 ${styles.headerLink} ${styles.popover}`}
        >
            {/* <div>A</div> */}
            <div>
                <Link to={useLocalePath(slug)} className={styles.linkTitle}>{title}</Link>
            </div>
            <div style={{marginLeft:"-10px"}}>
                <svg width="15" height="10" viewBox="0 0 10 6" fill="black" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.65575 0.896124L4.99975 5.55212L0.343751 0.896123L1.01575 0.224122L4.99975 4.20812L8.98375 0.224123L9.65575 0.896124Z" fill="black" />
                </svg>
            </div>
            {showMenu && (
                <div className={styles.list}>
                    {menuItems.map((subLink, index) => (
                        <Link className={`${styles.subLink}`} key={index} to={useLocalePath(subLink.slug)}>
                            {subLink.title}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MenuItems