module.exports = {
    mapLocale: {
        "en-CA": '',
        "fr-CA": 'fr/'
    },
    mapLangSelector: {
        "en-CA": 'Canada (English)',
        "fr-CA": 'Canada (French)'
    },
    mapAddressCountry: {
        "en-CA": 'Canada',
        "fr-CA": 'Canada'
    },
    mapFetchifyCode: {
        "en-CA": 'CAD',
        "fr-CA": 'CAD'
    },
    mapSiteName: {
        "en-CA": 'https://www.redrosetea.ca/',
        "fr-CA": 'https://www.redrosetea.ca/fr/'
    },
    cookieLang: {
        "en-CA": 'en',
        "fr-CA": 'fr'
    }
}