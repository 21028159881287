import React, { useState, useEffect } from 'react'
import { useLocale } from '../../../hooks'
import * as styles from './change-language.module.scss'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
const ChangeLanguage = () => {
    const locale = useLocale()
    const changeLang = (e) => {
        if (e.target.value === "fr-CA") {
            navigate('/fr')
        } else if (e.target.value === "en-CA") {
            navigate('/')
        }
    }
    const { pathname } = useLocation()
    useEffect(() => {
        if (locale === 'fr-CA') {
            let a = document.getElementById('change-lang')
            a.value = "fr-CA"
        } else if (locale === "en-CA") {
            let a = document.getElementById('change-lang')
            a.value = "en-CA"
        }
    }, [pathname])

    return (
        <div>
            <select id="change-lang" onChange={(e) => changeLang(e)} className={styles.link}>
                <option value="en-CA" id="english">English</option>
                <option value="fr-CA" id="french">Français</option>
            </select>
        </div>
    )
}

export default ChangeLanguage