import React, { useState } from 'react'
import * as styles from './mobile-header.module.scss'
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { useLocalePath } from '../../../hooks'
const MobileHeader = ({
    items,
    logo
}) => {

    const [open, setOpen] = useState(false)

    const openMenu = () => {
        if (!open) {
            document.querySelector(`.${styles.menuButton}`).classList.add(styles.open)
            document.querySelector(`.${styles.listContainer}`).classList.add(styles.listOpen)
            setOpen(!open)
        } else {
            document.querySelector(`.${styles.menuButton}`).classList.remove(styles.open)
            document.querySelector(`.${styles.listContainer}`).classList.remove(styles.listOpen)
            setOpen(!open)
        }
    }

    const closeMenu = () => {
        document.querySelector(`.${styles.menuButton}`).classList.remove(styles.open)
        document.querySelector(`.${styles.listContainer}`).classList.remove(styles.listOpen)
        setOpen(!open)
    }

    return (
        <div>
            <div className={styles.mobileHeader}>
                <div className={styles.header}>
                    <div className={styles.logoImageContainer}>
                        <Link to={useLocalePath('')}>
                            <GatsbyImage image={logo.gatsbyImageData} alt="Red Rose Mobile Header Logo" />
                        </Link>
                    </div>
                    <div className={styles.menuButton} onClick={() => openMenu()}>
                        <div className={styles.menuButtonContent}></div>
                    </div>
                </div>
                <div id="mobile-menu" className={`${styles.listContainer}`}>
                    <div className={`${styles.list}`}>
                        {items.map(({ title, slug, menuItems }, index) => {
                            return (
                                <div key={index}>
                                    <div className={styles.menuTitle}>
                                        <Link to={useLocalePath(slug)} onClick={() => { closeMenu() }}>{title}</Link>
                                    </div>
                                    <div>
                                        {
                                            menuItems?.map((item, index) => {
                                                return (
                                                    <Link to={useLocalePath(item.slug)} className={styles.menuSubTitle} key={index} onClick={() => { closeMenu() }}>{item.title}</Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileHeader