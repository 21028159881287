exports.components = {
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-contact-tsx": () => import("./../../../src/templates/PageContact.tsx" /* webpackChunkName: "component---src-templates-page-contact-tsx" */),
  "component---src-templates-page-not-found-tsx": () => import("./../../../src/templates/PageNotFound.tsx" /* webpackChunkName: "component---src-templates-page-not-found-tsx" */),
  "component---src-templates-page-search-tsx": () => import("./../../../src/templates/PageSearch.tsx" /* webpackChunkName: "component---src-templates-page-search-tsx" */),
  "component---src-templates-page-sitemap-tsx": () => import("./../../../src/templates/PageSitemap.tsx" /* webpackChunkName: "component---src-templates-page-sitemap-tsx" */),
  "component---src-templates-page-thank-you-tsx": () => import("./../../../src/templates/PageThankYou.tsx" /* webpackChunkName: "component---src-templates-page-thank-you-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-category-tsx": () => import("./../../../src/templates/ProductsCategory.tsx" /* webpackChunkName: "component---src-templates-products-category-tsx" */)
}

